import $ from "jquery";

// remove old value store when ttl has ended after 90 days
var keys = Object.keys(localStorage);
keys.forEach(key => {
  var hidding_section = localStorage.getItem(key)
  try {
    var hidding_section_json = JSON.parse(hidding_section);
    if (hidding_section_json.expiry < new Date().getTime()) {
      localStorage.removeItem(key);
    }
  } catch (e) { }
})


// function that add a ttl in days with value in local storage
function setWithExpiry(key, value, ttl) {
  const now = new Date()
  //convert days in milliseconds
  ttl = ttl * 24 * 60 * 60 * 1000
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

$(".hide_carret").each(function (index, element) {
  var portal_url = $("body").attr("data-portal-url")
  let img = document.createElement("img");
  img.setAttribute("src", portal_url+"/++plone++mirabell.chanel.static/src/assets/img/carret.svg")
  img.setAttribute("class", "carret visible");

  var elementID = $(this).find("a").attr("href");
  try {
    // if nothing is stored for that element, raise exception and leave the block
    var sectionState = JSON.parse(localStorage.getItem(elementID)).value;

    // otherwise, set the carrets as last viewed by the user
    if (sectionState == "collapsed") {
      img.setAttribute("class", "carret collapsed");
    }
    if (sectionState == "visible") {
      img.setAttribute("class", "carret visible");
    }
  } catch (e) { }

  element.prepend(img);
})

function hideSection(element){
  var elementID = element.siblings(".link-warper").find("a").attr("href");
  if (element.hasClass("visible")) {
    element.removeClass("visible");
    element.addClass("collapsed");
    setWithExpiry(elementID, "collapsed", 90);
  } else {
    element.removeClass("collapsed");
    element.addClass("visible");
    setWithExpiry(elementID, "visible", 90)
  }
}

$('.dashboard-tree .carret').click(function () {
  var element = $(this);
  hideSection(element);
});

$('.documentFirstHeading .toggle-all').click(function () {
  const toggle_all_button = $(this);

  const switch_carrets = function(from_class, to_class) {
    // switch toggle button appearance
    toggle_all_button.removeClass(from_class).addClass(to_class);
    // switch every matching carret to the other state
    $('.dashboard-tree .carret.' + from_class).each(function () {
      $(this).removeClass(from_class).addClass(to_class);
    });
  };

  if (toggle_all_button.hasClass("visible")) {
    switch_carrets("visible", "collapsed");
  } else if (toggle_all_button.hasClass("collapsed")) {
    switch_carrets("collapsed", "visible");
  }
});

$('span.shop-applied input').on('click', function (){
  var element = $(this).closest("li").find(".carret");
  var inputState = $(this).prop("checked");
  var sectionState = element.hasClass("visible");
  if (inputState == sectionState) {
    hideSection(element);
  }
})
