import $ from "jquery";

$('.section-axis').find("label[title='3 axis'], label[title='3 AXIS']").closest('li').css('display', 'none');

var axis_li_count = 0;
$('.section-axis').find("li").each(function () {
  //count only element that not display none in
  if ($(this).css('display') != 'none') {
    axis_li_count++;
  }
})

//if there is a odd number of element then add span 2 to grid-column to the lasrt element that is not in display none
if (axis_li_count % 2 != 0) {
  var li_axis = $('.section-axis').find("li");
  
  var find = false;
  var countdown = li_axis.length-1;
  while(!find || countdown < 0) {
    if($(li_axis[countdown]).css('display') != 'none'){
      find = true;
      $(li_axis[countdown]).css('grid-column','span 2');
    }
    countdown--;
  }
}