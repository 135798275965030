import $ from "jquery";

if (!$("body").hasClass("template-facetednavigation_view")) {
  $("#portlet-toggler").on("click", function () {
    $("#portlet-burger").toggleClass("active");
    $("body").toggleClass("portlet-opened");
  });
  $("#setting-toggler").hide();
}

if ($("body").hasClass("template-facetednavigation_view")) {
  var centerTopArea = $("#content #center-top-area");
  var header = $("#content header");
  var factedViewBtn = $("#c99_widget ul li:nth-child(1) input");
  var folderViewBtn = $("#c99_widget ul li:nth-child(2) input");

  function activeFacetedView() {
    $("#setting-toggler:not(.active)").addClass("active");
    $("#portlet-toggler.active").removeClass("active");
    $("#portlet-burger>#left-area:not(.active)").addClass("active");
    $("#portlet-burger>.nav.active").removeClass("active");
    centerTopArea.show();
    header.show();
  }

  function activeFolderView() {
    $("#setting-toggler.active").removeClass("active");
    $("#portlet-toggler:not(.active)").addClass("active");
    $("#portlet-burger>#left-area.active").removeClass("active");
    $("#portlet-burger>.nav:not(.active)").addClass("active");
    centerTopArea.hide();
    header.hide();
  }

  $(function () {
    $("#c99_widget").hide();
    $("#portlet-burger").addClass("active");
    // check what the default config when document is ready
    if (factedViewBtn.is(":checked")) {
      activeFacetedView();
    } else {
      activeFolderView();
    }
  });

  $("#setting-toggler").on("click", function () {
    // toggle the facted view
    activeFacetedView();
    factedViewBtn.trigger("click");
  });

  $("#portlet-toggler").on("click", function () {
    // toggle the folder view
    activeFolderView();
    folderViewBtn.trigger("click");
  });
}